import React, { useState } from 'react'
import NavbarMain from '../../components/navbarMain/navbarMain'
import Grid from '@mui/material/Grid';
import "./chooseTemplate.css"
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import transition from '../../transition';
import ChooseTemplateV1 from './components/template-v1';
import ChooseTemplateV2 from './components/template-v2';
import ChooseTemplateV3 from './components/template-v3';

const ChooseTemplate = () => {
    const navigate = useNavigate();
    const [templateSelect, settemplateSelect] = useState<string>("")

    const saveTemplateInfo = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        if (!templateSelect) {
            alert("please choose one template first!")
        } else {
            localStorage.setItem("templateType", templateSelect)
            navigate("/create_card/")
        }
        console.log(templateSelect)
    }



    return (
        <>
            <NavbarMain />
            {/* <Loader className={isLoading ? "active" : "disap"} /> */}
            <Grid container spacing={4} className='myProfile myCards chooseTemplate'>
                <Grid item xs={12}>
                    <Typography variant="h5" className='sevtionHeader'>Card template</Typography>
                </Grid>
                <ChooseTemplateV1/>
                <ChooseTemplateV2/>
                <ChooseTemplateV3/>

                {/* <Grid style={{ textAlign: "center", marginTop: "50px" }} item xs={12}>
                    <label htmlFor="v1">v1</label>
                    <input type="radio" onChange={(e)=>(settemplateSelect(e.target.value))} name="templateSelect" id="v1" value="v1" />
                    <label htmlFor="v2">v2</label>
                    <input type="radio" onChange={(e)=>(settemplateSelect(e.target.value))} name="templateSelect" id="v2" value="v2" />
                    <label htmlFor="v3">v3</label>
                    <input type="radio" onChange={(e)=>(settemplateSelect(e.target.value))} name="templateSelect" id="v3" value="v3" />
                    <button onClick={handleSubmit}>Next</button>
                </Grid> */}
            </Grid>
        </>
    )
}

export default transition(ChooseTemplate)
