import axios from "axios";

const THROTTLE_TIME_MS = 3600000; // 1 hour in milliseconds

export function trackUserLocationForShare(cardId: string): void {
    const now = new Date().getTime();
    const lastCallTime = localStorage.getItem('lastCallTime');
    
    // If there's no record of the last call time or the time is older than the throttle period, proceed
    if (!lastCallTime || now - parseInt(lastCallTime, 10) >= THROTTLE_TIME_MS) {
        // Update the last call time in localStorage
        localStorage.setItem('lastCallTime', now.toString());
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    const city = await getCityFromCoordinates(latitude, longitude);
                    const deviceType = getDeviceType();
                    sendLocationAndDeviceTypeToServer(cardId, city, deviceType);
                },
                (error) => {
                    console.error("Geolocation error: ", error);
                }
            );
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    } else {
        console.log("Ignoring call due to throttle limit.");
    }
}

const getCityFromCoordinates = async (latitude: number, longitude: number): Promise<string | null> => {
    try {
        const response = await axios.get(`https://api.bigdatacloud.net/data/reverse-geocode-client`, {
            params: {
                latitude,
                longitude,
                localityLanguage: 'en'
            }
        });

        return response.data.city || response.data.locality || null;
    } catch (error: any) {
        console.log("Failed to get city from coordinates:", error.message);
        return null;
    }
};

const getDeviceType = (): string | null => {
    if (/Mobi|Android/i.test(navigator.userAgent)) {
        return 'Mobile';
    } else if (/iPad|Tablet/i.test(navigator.userAgent)) {
        return 'Tablet';
    } else {
        return 'Desktop';
    }
};

const sendLocationAndDeviceTypeToServer = async (cardId: string, city: string | null, deviceType: string | null) => {
    try {
        await axios.post(`${process.env.REACT_APP_BASE_URL}cards/getLocation/${cardId}`, { city, deviceType }).then(res=>console.log(res.data));
        console.log(`Location and device type sent: ${city}, ${deviceType}`);
    } catch (error: any) {
        console.log("Failed to send location and device type to server:", error.message);
    }
};
