import axios from 'axios';
export const verifyCard = async (cardToken: string, verificationCode: string) => {
    try {
        const verifyCardPayload = {
            id: 125,
            method: 'cards.verify',
            params: {
                token: cardToken,
                code: verificationCode
            }
        };

        const verifyResponse = await axios.post(process.env.REACT_APP_PAYMENT_GATEWAY_URL || "", verifyCardPayload, {
            headers: {
                'X-auth': process.env.REACT_APP_PAYMENT_GATEWAY_KEY,
                'Content-Type': 'application/json'
            }
        });

        if (verifyResponse.data && verifyResponse.data.result) {
            console.log('Card verified successfully:', verifyResponse.data);
            return { success: true, message: 'Card verified successfully' };
        } else {
            console.error('Card verification failed:', verifyResponse.data);
            return { success: false, message: 'Failed to verify the card.' };
        }

    } catch (error) {
        console.error('Error verifying card:', error);
        return { success: false, message: 'An error occurred while verifying the card.' };
    }
};