// src/api/requestVerificationCode.js
import axios from 'axios';

// Function to request a verification code from Payme API
export const requestVerificationCode = async (cardToken: string) => {
    try {
        // Payload for requesting verification code
        const getVerifyCodePayload = {
            id: 124,  // Unique ID for the request
            method: 'cards.get_verify_code',
            params: {
                token: cardToken  // Token returned from cards.create
            }
        };

        // Sending the request to Payme API
        const verifyCodeResponse = await axios.post(process.env.REACT_APP_PAYMENT_GATEWAY_URL || "", getVerifyCodePayload, {
            headers: {
                'X-auth': process.env.REACT_APP_PAYMENT_GATEWAY_KEY, // Replace with your Payme auth token
                'Content-Type': 'application/json'
            }
        });

        // If verification code request is successful
        if (verifyCodeResponse.data && verifyCodeResponse.data.result) {
            console.log('Verification code sent successfully');
            return { success: true, message: 'Verification code sent successfully' };
        } else {
            // If verification code request fails
            console.error('Error requesting verification code:', verifyCodeResponse.data);
            return { success: false, message: 'Failed to request verification code.' };
        }

    } catch (error) {
        // Handle errors during verification code request
        console.error('Error requesting verification code:', error);
        return { success: false, message: 'An error occurred while requesting the verification code.' };
    }
};
