import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const paymentCardApi = createApi({
    reducerPath: "paymentApi",
    baseQuery: fetchBaseQuery({
        // Local
        // baseUrl: "http://localhost:8080/"
        baseUrl: process.env.REACT_APP_BASE_URL
        // Server
        // baseUrl: "https://bizzcard-back.onrender.com/"
    }),
    endpoints: (builder) => ({
        createNewPaymentCard: builder.mutation({
            query: (payload) => ({
                headers: {
                    authorization: `Bearer ${payload.token}`,
                },
                url: `/payments/create_payment_card/${payload.ownerId}`,
                body: payload.body,
                method: "POST",
            })
        }),
        setVerifyToTrue: builder.mutation({
            query: (payload) => ({
                headers: {
                    authorization: `Bearer ${payload.token}`,
                },
                url: `/payments/set_verify_to_true/`,
                body: payload.body,
                method: "POST",
            })
        }),
    })
});

export const {  
    useCreateNewPaymentCardMutation,
    useSetVerifyToTrueMutation
} = paymentCardApi;