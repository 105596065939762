import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
type PropsType = {
    cities: Object,
}
type CitiesType = {
    [city: string]: number;
}
export default function LocationsTable(props:PropsType) {
    const data = Object.entries(props.cities).map(([key, value]) => ({
        name: key,
        count: value
    }));
    useEffect(() => {
        console.log(data)
    }, [data])

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableBody>
                    {data.map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="right">{row.count}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}


function createData(
    name: string,
    calories: number,
) {
    return { name, calories };
}

const rows = [
    createData('Warsaw', 159),
    createData('Tashkent', 237),
    createData('Moscow', 262),
    createData('New York', 305),
];

