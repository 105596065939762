// Payment.tsx
import React, { useContext, useState, FormEvent, ChangeEvent, useEffect } from 'react';
import { Button, TextField, Modal, Box, Typography, Link, CircularProgress } from '@mui/material';
import './payment.css'; // Import the CSS file
import PaymeLogo from '../../assets/images/Paymeuz_logo.png';
import axios from 'axios';
import { requestVerificationCode } from '../../utils/requestVerificationCode';
import { verifyCard } from '../../utils/verifyCard';
import { useCreateNewPaymentCardMutation, useSetVerifyToTrueMutation } from '../../services/paymentsApi';
import { StateContext } from '../../context/useContext';
import { useNavigate } from 'react-router';

// Define the types for the context and props
interface PaymentProps {
    refetch: () => Promise<any>; // Type for refetch function
}

interface GlobalUser {
    id: string;
    name?: string;
    subscription: string,
}

// Main component
const Payment: React.FC<PaymentProps> = ({ refetch }) => {
    // Context type and state initialization
    const { globalUser } = useContext(StateContext) as { globalUser: GlobalUser };
    // Mutations from RTK Query
    const [newCard, resultNewCard] = useCreateNewPaymentCardMutation();
    const [cardVerify, resultVerify] = useSetVerifyToTrueMutation();

    const navigate = useNavigate();

    // Navigate to other page if user already has PREMIUM subcription
    // useEffect(() => {
    //     if (globalUser.subscription == "PREMIUM") {
    //         navigate("/my_cards")
    //     }
    //     if (resultVerify.isSuccess) {
    //         navigate("/my_cards")
    //     }
    // }, [resultVerify.isLoading])


    const token = localStorage.getItem('token') || ''; // Fallback to empty string

    // State variables with types
    const [cardNumber, setCardNumber] = useState<string>('');
    const [expiryDate, setExpiryDate] = useState<string>('');
    const [errorFromPayme, setErrorFromPayme] = useState<string>('');
    const [smsCode, setSmsCode] = useState<string>('');
    const [cardToken, setCardToken] = useState<string>('');
    const [showModal, setShowModal] = useState<boolean>(false);


    // Handler to restrict card number to 16 digits
    const handleCardNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
        if (value.length <= 16) {
            setCardNumber(value);
        }
    };

    // Handler to automatically format expiry date as MM/YY
    const handleExpiryDateChange = (e: ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
        if (value.length > 2) {
            value = `${value.slice(0, 2)}/${value.slice(2)}`; // Add "/" after MM
        }
        setExpiryDate(value);
    };

    // Handle card submission
    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        // Prepare the request payload
        const payload = {
            id: 123,
            method: 'cards.create',
            params: {
                card: {
                    number: cardNumber, // Card number (e.g., '8600069195406311')
                    expire: expiryDate, // Expiry date (e.g., '0399')
                },
                save: true, // Set to true to save the card token for future use
            },
        };

        try {
            // Make the POST request to Payme API
            const response = await axios.post(process.env.REACT_APP_PAYMENT_GATEWAY_URL!, payload, {
                headers: {
                    'X-auth': process.env.REACT_APP_PAYMENT_GATEWAY_KEY!,
                    'Content-Type': 'application/json',
                },
            });

            // Check if the response is successful
            if (response.data && response.data.result) {
                const cardToken = response.data.result.card.token;
                console.log('Card created successfully:', cardToken);

                const newCardPayload = response.data.result.card;
                delete newCardPayload.recurrent;
                delete newCardPayload.type;

                // Send new Card info to your server
                const responseNewCard = await newCard({ token, body: newCardPayload, ownerId: globalUser.id });
                console.log(responseNewCard);

                // Request verification code using the card token
                const verifyCodeResult = await requestVerificationCode(cardToken);
                if (verifyCodeResult.success) {
                    setCardToken(cardToken);
                    setShowModal(true);
                } else {
                    setErrorFromPayme('Failed to create card. Please try again.');
                }
            } else {
                setErrorFromPayme('Failed to create card. Please try again.');
            }
        } catch (error) {
            setErrorFromPayme('Something went wrong with your card. Please try again.');
        }
    };

    // Handle card verification
    const handleVerificationSubmit = async (e: FormEvent) => {
        e.preventDefault();

        const verifyResult = await verifyCard(cardToken, smsCode);

        if (verifyResult.success) {
            alert('Card verified successfully!');

            const verifyPayload = {
                token,
                body: { token: cardToken },
            };

            await cardVerify(verifyPayload);
            refetch()
            const savedUser = localStorage.getItem('user');

            if (savedUser) {
                const userObject = JSON.parse(savedUser);

                userObject.subscription = 'PREMIUM';

                localStorage.setItem('user', JSON.stringify(userObject));
            }

            setShowModal(false);
        } else {
            setErrorFromPayme(verifyResult.message);
        }
    };

    return (
        <>
            <Box className="formContainer">
                <Typography variant="h5" align="center" gutterBottom>
                    Enter Your Card Details
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        className="textField"
                        label="Card Number"
                        variant="outlined"
                        fullWidth
                        required
                        value={cardNumber}
                        onChange={handleCardNumberChange}
                        inputProps={{ maxLength: 16 }}
                        autoComplete="off"
                    />
                    <TextField
                        className="textField"
                        label="Expiration Date (MM/YY)"
                        variant="outlined"
                        fullWidth
                        required
                        value={expiryDate}
                        onChange={handleExpiryDateChange}
                        inputProps={{ maxLength: 5 }} // Limit input to 5 characters (MM/YY)
                        autoComplete="off"
                    />
                    {errorFromPayme && <Typography color="red">{errorFromPayme}</Typography>}
                    <Button disabled={resultNewCard.isLoading ? true : false} className="submitButton" type="submit" variant="contained" color="primary" fullWidth>
                        {resultNewCard.isLoading && <CircularProgress color='inherit' style={{ width: "20px", height: "20px", marginRight: "15px" }} />}
                        Submit
                    </Button>
                </form>
                <Typography className="infoText" variant="body2">
                    All user data is securely processed and stored on Payme Business servers. No data is transmitted to the merchant.
                </Typography>
                <Typography className="linkText" variant="body2">
                    By proceeding, you agree to the{' '}
                    <Link href="https://cdn.payme.uz/terms/main.html?target=_blank" target="_blank" rel="noopener">
                        Payme Terms and Conditions.
                    </Link>
                </Typography>
                <img className="logo" src={PaymeLogo} alt="Payme Logo" />
            </Box>
            <Modal open={showModal} onClose={() => setShowModal(false)}>
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Enter SMS Verification Code
                    </Typography>
                    <form onSubmit={handleVerificationSubmit}>
                        <TextField
                            fullWidth
                            label="SMS Code"
                            variant="outlined"
                            value={smsCode}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setSmsCode(e.target.value)}
                            required
                            sx={{ mt: 2 }}
                        />
                        <Button disabled={resultNewCard.isLoading ? true : false} variant="contained" color="primary" type="submit" fullWidth sx={{ mt: 3 }}>
                            {resultVerify.isLoading && <CircularProgress color='inherit' style={{ width: "20px", height: "20px", marginRight: "15px" }} />}
                            Verify Card
                        </Button>
                    </form>
                </Box>
            </Modal>
        </>
    );
};

export default Payment;
