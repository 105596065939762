import React, { useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram';
import { CustomTextField } from './utils';
import { useSendEmailMutation } from '../../services/cardsApi';
import "./profileForm.css";

interface FormData {
    phone: string;
    name: string;
    email: string;
    message: string;
}

type Props = {
    toEmail: string,
    toName: string
}

export default function ProfileForm({ toEmail, toName }: Props) {
    const [sendEmail, { isLoading, isSuccess, isError }] = useSendEmailMutation(); // Initialize mutation hook

    // State to handle form data
    const [formData, setFormData] = useState<FormData>({
        phone: '',
        name: '',
        email: '',
        message: ''
    });

    // Handle form input change
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Handle form submission
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            // Sending form data to the backend
            await sendEmail({
                from: formData.email,
                fromName: formData.name,
                fromPhone: formData.phone,
                text: formData.message,
                to: toEmail, // Replace with actual recipient email
                toName: toName // Replace with actual recipient name
            }).unwrap(); // Use unwrap to handle success/error directly

            if (isSuccess) {
                alert('Email sent successfully!');
            }
        } catch (error) {
            console.error("Error sending email:", error);
        }
    };

    return (
        <div className='profileForm'>
            <Typography className="heading" variant='h5'>
                Чтобы получить первичную консультацию и записаться на прием, нажмите здесь:
            </Typography>
            <div className="iconBocx">
                <TelegramIcon />
            </div>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h5'>
                            Оставьте детали и я свяжусь с вами
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomTextField
                            label="Телефон"
                            variant="outlined"
                            fullWidth
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomTextField
                            label="Имя"
                            variant="outlined"
                            fullWidth
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomTextField
                            label="Электронная почта"
                            variant="outlined"
                            fullWidth
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <textarea
                            placeholder='Ваше сообщение'
                            className='messageBox'
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                        ></textarea>
                    </Grid>
                    <Grid item xs={5} md={7}></Grid>
                    <Grid item xs={7} md={5}>
                        <Button
                            className='submitBtn'
                            fullWidth
                            variant="contained"
                            type="submit"
                            disabled={isLoading}
                        >
                            {isLoading ? 'Sending...' : 'Отправить'}
                        </Button>
                    </Grid>
                </Grid>
                {isSuccess && <Typography className='successMessage'>Message sent successfully!</Typography>}
                {isError && <Typography className='errorMessage'>Error sending message. Please try again.</Typography>}
            </form>
        </div>
    );
}
