import React from 'react'

export default function UzFlag() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="3" y="5" width="18" height="14" rx="3" stroke="#303030"/>
        <mask id="mask0_56_170" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="3" y="5" width="18" height="14">
        <path d="M20.5 16C20.5 17.3807 19.3807 18.5 18 18.5H6.00002C4.61931 18.5 3.50002 17.3807 3.50001 16L3.5 8.00002C3.5 6.6193 4.6193 5.50001 6.00002 5.50002L18 5.50012C19.3807 5.50013 20.5 6.61941 20.5 8.00012L20.5 16Z" fill="#F5F5F5"/>
        </mask>
        <g mask="url(#mask0_56_170)">
        <path d="M22.342 9.83335H2.5V5.84212C2.5 5.65319 2.65319 5.5 2.84212 5.5H22C22.1889 5.5 22.3421 5.65319 22.3421 5.84212L22.342 9.83335Z" fill="#82AFFF"/>
        <path d="M21.9999 18.5003H2.84212C2.65319 18.5003 2.5 18.3471 2.5 18.1582V14.167H22.342V18.1582C22.342 18.3472 22.1889 18.5003 21.9999 18.5003Z" fill="#73AF00"/>
        <path d="M22.342 9.83301H2.5V14.1661H22.342V9.83301Z" fill="#F5F5F5"/>
        <path d="M22.342 9.44824H2.5V9.98855H22.342V9.44824Z" fill="#FF4B55"/>
        <path d="M22.342 14.0107H2.5V14.5511H22.342V14.0107Z" fill="#FF4B55"/>
        <path d="M6.89781 8.74727C6.19745 8.74727 5.62974 8.18092 5.62974 7.48308C5.62974 6.78268 6.19741 6.21377 6.89781 6.21377C6.90052 6.21377 6.90316 6.21377 6.90579 6.21381C6.94703 6.21435 6.96148 6.15959 6.92513 6.1401C6.70947 6.0245 6.46334 5.95908 6.20167 5.96098C5.36366 5.96695 4.68295 6.66138 4.69186 7.49939C4.70062 8.32877 5.37866 9.0001 6.21264 9.0001C6.4707 9.0001 6.71338 8.9348 6.92637 8.82079C6.96261 8.80137 6.94796 8.74662 6.90688 8.7472C6.90378 8.74727 6.90079 8.74727 6.89781 8.74727Z" fill="#F5F5F5"/>
        <path d="M7.89022 8.39734L7.8336 8.56716L7.65459 8.56852C7.63142 8.56867 7.62181 8.59828 7.64045 8.61204L7.78446 8.71838L7.73044 8.88905C7.72346 8.91114 7.74861 8.92943 7.76748 8.91595L7.91312 8.81185L8.05876 8.91595C8.07763 8.92943 8.10275 8.91114 8.09581 8.88905L8.04179 8.71838L8.18576 8.61204C8.2044 8.59828 8.19479 8.56867 8.17161 8.56852L7.99261 8.56716L7.93599 8.39734C7.92866 8.37537 7.89758 8.37537 7.89022 8.39734Z" fill="#F5F5F5"/>
        <path d="M9.15115 8.39734L9.09453 8.56716L8.91552 8.56852C8.89235 8.56867 8.88273 8.59828 8.90137 8.61204L9.04535 8.71838L8.99132 8.88905C8.98435 8.91114 9.0095 8.92943 9.02837 8.91595L9.17402 8.81185L9.31966 8.91595C9.33853 8.92943 9.36364 8.91114 9.35671 8.88905L9.30268 8.71838L9.44666 8.61204C9.4653 8.59828 9.45568 8.56867 9.43251 8.56852L9.2535 8.56716L9.19688 8.39734C9.18956 8.37537 9.15844 8.37537 9.15115 8.39734Z" fill="#F5F5F5"/>
        <path d="M10.4125 8.39734L10.3559 8.56716L10.1769 8.56852C10.1537 8.56867 10.1441 8.59828 10.1627 8.61204L10.3067 8.71838L10.2527 8.88905C10.2457 8.91114 10.2709 8.92943 10.2898 8.91595L10.4354 8.81185L10.581 8.91595C10.5999 8.92943 10.625 8.91114 10.6181 8.88905L10.5641 8.71838L10.708 8.61204C10.7267 8.59828 10.7171 8.56867 10.6939 8.56852L10.5149 8.56716L10.4583 8.39734C10.4509 8.37537 10.4198 8.37537 10.4125 8.39734Z" fill="#F5F5F5"/>
        <path d="M11.6738 8.39734L11.6172 8.56716L11.4382 8.56852C11.4151 8.56867 11.4054 8.59828 11.4241 8.61204L11.5681 8.71838L11.514 8.88905C11.5071 8.91114 11.5322 8.92943 11.5511 8.91595L11.6967 8.81185L11.8424 8.91595C11.8612 8.92943 11.8863 8.91114 11.8794 8.88905L11.8254 8.71838L11.9693 8.61204C11.988 8.59828 11.9784 8.56867 11.9552 8.56852L11.7762 8.56716L11.7196 8.39734C11.7123 8.37537 11.6812 8.37537 11.6738 8.39734Z" fill="#F5F5F5"/>
        <path d="M12.9344 8.39734L12.8777 8.56716L12.6987 8.56852C12.6755 8.56867 12.6659 8.59828 12.6846 8.61204L12.8286 8.71838L12.7746 8.88905C12.7676 8.91114 12.7927 8.92943 12.8116 8.91595L12.9573 8.81185L13.1029 8.91595C13.1218 8.92943 13.1469 8.91114 13.14 8.88905L13.0859 8.71838L13.2299 8.61204C13.2486 8.59828 13.239 8.56867 13.2158 8.56852L13.0368 8.56716L12.9802 8.39734C12.9728 8.37537 12.9417 8.37537 12.9344 8.39734Z" fill="#F5F5F5"/>
        <path d="M9.15115 7.22064L9.09453 7.39046L8.91552 7.39182C8.89235 7.39197 8.88273 7.42158 8.90137 7.43534L9.04535 7.54168L8.99132 7.71235C8.98435 7.73444 9.0095 7.75274 9.02837 7.73925L9.17402 7.63516L9.31966 7.73925C9.33853 7.75274 9.36364 7.73444 9.35671 7.71235L9.30268 7.54168L9.44666 7.43534C9.4653 7.42158 9.45568 7.39197 9.43251 7.39182L9.2535 7.39046L9.19688 7.22064C9.18956 7.19859 9.15844 7.19859 9.15115 7.22064Z" fill="#F5F5F5"/>
        <path d="M10.4125 7.22064L10.3559 7.39046L10.1769 7.39182C10.1537 7.39197 10.1441 7.42158 10.1627 7.43534L10.3067 7.54168L10.2527 7.71235C10.2457 7.73444 10.2709 7.75274 10.2898 7.73925L10.4354 7.63516L10.581 7.73925C10.5999 7.75274 10.625 7.73444 10.6181 7.71235L10.5641 7.54168L10.708 7.43534C10.7267 7.42158 10.7171 7.39197 10.6939 7.39182L10.5149 7.39046L10.4583 7.22064C10.4509 7.19859 10.4198 7.19859 10.4125 7.22064Z" fill="#F5F5F5"/>
        <path d="M11.6738 7.22064L11.6172 7.39046L11.4382 7.39182C11.4151 7.39197 11.4054 7.42158 11.4241 7.43534L11.5681 7.54168L11.514 7.71235C11.5071 7.73444 11.5322 7.75274 11.5511 7.73925L11.6967 7.63516L11.8424 7.73925C11.8612 7.75274 11.8863 7.73444 11.8794 7.71235L11.8254 7.54168L11.9693 7.43534C11.988 7.42158 11.9784 7.39197 11.9552 7.39182L11.7762 7.39046L11.7196 7.22064C11.7123 7.19859 11.6812 7.19859 11.6738 7.22064Z" fill="#F5F5F5"/>
        <path d="M12.9344 7.22064L12.8777 7.39046L12.6987 7.39182C12.6755 7.39197 12.6659 7.42158 12.6846 7.43534L12.8286 7.54168L12.7746 7.71235C12.7676 7.73444 12.7927 7.75274 12.8116 7.73925L12.9573 7.63516L13.1029 7.73925C13.1218 7.75274 13.1469 7.73444 13.14 7.71235L13.0859 7.54168L13.2299 7.43534C13.2486 7.42158 13.239 7.39197 13.2158 7.39182L13.0368 7.39046L12.9802 7.22064C12.9728 7.19859 12.9417 7.19859 12.9344 7.22064Z" fill="#F5F5F5"/>
        <path d="M10.4125 8.39734L10.3559 8.56716L10.1769 8.56852C10.1537 8.56867 10.1441 8.59828 10.1627 8.61204L10.3067 8.71838L10.2527 8.88905C10.2457 8.91114 10.2709 8.92943 10.2898 8.91595L10.4354 8.81185L10.581 8.91595C10.5999 8.92943 10.625 8.91114 10.6181 8.88905L10.5641 8.71838L10.708 8.61204C10.7267 8.59828 10.7171 8.56867 10.6939 8.56852L10.5149 8.56716L10.4583 8.39734C10.4509 8.37537 10.4198 8.37537 10.4125 8.39734Z" fill="#F5F5F5"/>
        <path d="M10.4125 6.04382L10.3559 6.21364L10.1769 6.215C10.1537 6.21516 10.1441 6.24476 10.1627 6.25852L10.3067 6.36486L10.2527 6.53553C10.2457 6.55762 10.2709 6.57592 10.2898 6.56243L10.4354 6.45834L10.581 6.56243C10.5999 6.57592 10.625 6.55762 10.6181 6.53553L10.5641 6.36486L10.708 6.25852C10.7267 6.24476 10.7171 6.21516 10.6939 6.215L10.5149 6.21364L10.4583 6.04382C10.4509 6.02185 10.4198 6.02185 10.4125 6.04382Z" fill="#F5F5F5"/>
        <path d="M11.6738 8.39734L11.6172 8.56716L11.4382 8.56852C11.4151 8.56867 11.4054 8.59828 11.4241 8.61204L11.5681 8.71838L11.514 8.88905C11.5071 8.91114 11.5322 8.92943 11.5511 8.91595L11.6967 8.81185L11.8424 8.91595C11.8612 8.92943 11.8863 8.91114 11.8794 8.88905L11.8254 8.71838L11.9693 8.61204C11.988 8.59828 11.9784 8.56867 11.9552 8.56852L11.7762 8.56716L11.7196 8.39734C11.7123 8.37537 11.6812 8.37537 11.6738 8.39734Z" fill="#F5F5F5"/>
        <path d="M12.9344 8.39734L12.8777 8.56716L12.6987 8.56852C12.6755 8.56867 12.6659 8.59828 12.6846 8.61204L12.8286 8.71838L12.7746 8.88905C12.7676 8.91114 12.7927 8.92943 12.8116 8.91595L12.9573 8.81185L13.1029 8.91595C13.1218 8.92943 13.1469 8.91114 13.14 8.88905L13.0859 8.71838L13.2299 8.61204C13.2486 8.59828 13.239 8.56867 13.2158 8.56852L13.0368 8.56716L12.9802 8.39734C12.9728 8.37537 12.9417 8.37537 12.9344 8.39734Z" fill="#F5F5F5"/>
        <path d="M11.6738 6.04382L11.6172 6.21364L11.4382 6.215C11.4151 6.21516 11.4054 6.24476 11.4241 6.25852L11.5681 6.36486L11.514 6.53553C11.5071 6.55762 11.5322 6.57592 11.5511 6.56243L11.6967 6.45834L11.8424 6.56243C11.8612 6.57592 11.8863 6.55762 11.8794 6.53553L11.8254 6.36486L11.9693 6.25852C11.988 6.24476 11.9784 6.21516 11.9552 6.215L11.7762 6.21364L11.7196 6.04382C11.7123 6.02185 11.6812 6.02185 11.6738 6.04382Z" fill="#F5F5F5"/>
        <path d="M12.9344 6.04382L12.8777 6.21364L12.6987 6.215C12.6755 6.21516 12.6659 6.24476 12.6846 6.25852L12.8286 6.36486L12.7746 6.53553C12.7676 6.55762 12.7927 6.57592 12.8116 6.56243L12.9573 6.45834L13.1029 6.56243C13.1218 6.57592 13.1469 6.55762 13.14 6.53553L13.0859 6.36486L13.2299 6.25852C13.2486 6.24476 13.239 6.21516 13.2158 6.215L13.0368 6.21364L12.9802 6.04382C12.9728 6.02185 12.9417 6.02185 12.9344 6.04382Z" fill="#F5F5F5"/>
        </g>
        </svg>
        
    )
}
