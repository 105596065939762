import { Grid, Table } from '@mui/material'
import React, { useEffect } from 'react'
import Paper from '@mui/material/Paper';
import "./statisticsOfCards.css"
import ViewsIcon from "../../assets/images/web-visibility.png"
import ShareIcon from "../../assets/images/share.png"
import PieChartComponent from './components/pieChart';
import LocationsTable from './components/locationsTable';
type DeviceType = {
    Mobile: string,
    Tablet: string,
    Desktop: string,
}

type Card = {
    visits:number,
    shares:number,
    cityVisits:Object,
    deviceTypes:DeviceType,
}
type CustomObject = {
    card:Card,
}

export default function StatisticsOfCards(props:CustomObject) {
    let {card} = props;
    useEffect(() => {
        console.log(typeof card.cityVisits);
        console.log(card.deviceTypes);
    }, [])

    return (
        <Grid container className="staisticsOfCards">
            <Grid className='p-0-onMObile' paddingRight={3} item xs={12} md={4}>
                <Paper className="paper" elevation={5}>
                    <h3>Number of visits</h3>
                    <h1>{card.visits}</h1>
                    <div className="iconBox redBg">
                        <img src={ViewsIcon} alt="Icon" />
                    </div>
                </Paper>
                <Paper className="paper" elevation={5}>
                    <h3>Number of shares</h3>
                    <h1>{card.shares}</h1>
                    <div className="iconBox greenBg">
                        <img src={ShareIcon} alt="Icon" />
                    </div>
                </Paper>
            </Grid>
            <Grid className='p-0-onMObile' paddingLeft={3} item xs={12} md={8}>
                <h3 className="statTitles">Traffic Source</h3>
                <PieChartComponent devices={card.deviceTypes} />
                <h3 className="statTitles">№ of visits from Cities</h3>
                <LocationsTable cities={card.cityVisits} />
            </Grid>
        </Grid>
    )
}
