import React from 'react'

export default function Linkedin() {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_164_625" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="28">
                <rect width="28" height="28" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_164_625)">
                <path d="M3.64453 24V9.11133H8.48438V24H3.64453ZM7.82812 7.22461C7.35417 7.68034 6.76628 7.9082 6.06445 7.9082C5.36263 7.9082 4.77018 7.68034 4.28711 7.22461C3.81315 6.76888 3.57617 6.20378 3.57617 5.5293C3.57617 4.86393 3.81315 4.30339 4.28711 3.84766C4.77018 3.39193 5.36263 3.16406 6.06445 3.16406C6.76628 3.16406 7.35417 3.39193 7.82812 3.84766C8.30208 4.29427 8.53906 4.85482 8.53906 5.5293C8.53906 6.20378 8.30208 6.76888 7.82812 7.22461ZM10.8496 24V9.11133H15.5664V11.9141H15.6621C16.0449 10.9115 16.6237 10.1458 17.3984 9.61719C18.1823 9.08854 19.1393 8.82422 20.2695 8.82422C21.9193 8.82422 23.1908 9.31641 24.084 10.3008C24.9863 11.2852 25.4375 12.666 25.4375 14.4434V24H20.5977V15.4824C20.5977 14.6257 20.3926 13.9603 19.9824 13.4863C19.5814 13.0124 18.9889 12.7754 18.2051 12.7754C17.4395 12.7754 16.8288 13.0352 16.373 13.5547C15.9264 14.0742 15.7031 14.7487 15.7031 15.5781V24H10.8496Z" fill="url(#paint0_linear_164_625)" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_164_625" x1="2" y1="-2" x2="30.3423" y2="1.17624" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E8C488" />
                    <stop offset="1" stopColor="#8D6736" />
                </linearGradient>
            </defs>
        </svg>

    )
}
