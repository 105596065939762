import React from 'react'

export default function Network() {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M40 29C37.2361 29 35 26.7639 35 24C35 21.2361 37.2361 19 40 19C42.7639 19 45 21.2361 45 24C45 26.7639 42.7639 29 40 29ZM40 21C38.3439 21 37 22.3439 37 24C37 25.6561 38.3439 27 40 27C41.6561 27 43 25.6561 43 24C43 22.3439 41.6561 21 40 21Z" fill="white" stroke="white" />
            <path d="M40 13C37.2361 13 35 10.7639 35 8C35 5.23614 37.2361 3 40 3C42.7639 3 45 5.23614 45 8C45 10.7639 42.7639 13 40 13ZM40 5C38.3439 5 37 6.34386 37 8C37 9.65614 38.3439 11 40 11C41.6561 11 43 9.65614 43 8C43 6.34386 41.6561 5 40 5Z" fill="white" stroke="white" />
            <path d="M40 45C37.2361 45 35 42.7639 35 40C35 37.2361 37.2361 35 40 35C42.7639 35 45 37.2361 45 40C45 42.7639 42.7639 45 40 45ZM40 37C38.3439 37 37 38.3439 37 40C37 41.6561 38.3439 43 40 43C41.6561 43 43 41.6561 43 40C43 38.3439 41.6561 37 40 37Z" fill="white" stroke="white" />
            <path d="M8 29C5.23614 29 3 26.7639 3 24C3 21.2361 5.23614 19 8 19C10.7639 19 13 21.2361 13 24C13 26.7639 10.7639 29 8 29ZM8 21C6.34386 21 5 22.3439 5 24C5 25.6561 6.34386 27 8 27C9.65614 27 11 25.6561 11 24C11 22.3439 9.65614 21 8 21Z" fill="white" stroke="white" />
            <path d="M36 25H12C11.4561 25 11 24.5439 11 24C11 23.4561 11.4561 23 12 23H36C36.5439 23 37 23.4561 37 24C37 24.5439 36.5439 25 36 25Z" fill="white" stroke="white" />
            <path d="M36 41.5H28C23.16 41.5 20.5 38.84 20.5 34V14C20.5 9.16 23.16 6.5 28 6.5H36C36.82 6.5 37.5 7.18 37.5 8C37.5 8.82 36.82 9.5 36 9.5H28C24.84 9.5 23.5 10.84 23.5 14V34C23.5 37.16 24.84 38.5 28 38.5H36C36.82 38.5 37.5 39.18 37.5 40C37.5 40.82 36.82 41.5 36 41.5Z" fill="white" />
        </svg>
    )
}
