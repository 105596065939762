import React from 'react'
import Grid from '@mui/material/Grid';
import "../chooseTemplate.css"
import { Skeleton, Typography } from '@mui/material';
import BackgroundImg from "../../../assets/images/profileBg.png";
import ProfileDefault from '../../../assets/images/profileDefault.png'
import Car from '../../../assets/images/car';
import Sms from '../../../assets/images/sms';
import Linkedin from '../../../assets/images/linkedin';
import Facebookcard from '../../../assets/images/facebookcard';
import Telegramcard from '../../../assets/images/telegramcard';
import PhoneCard from '../../../assets/images/phoneCard';
import { useNavigate } from 'react-router';

export default function ChooseTemplateV1() {
    const navigate = useNavigate();
    return (
        <Grid onClick={() => { navigate("/template_v1") }} className='card-item v1' item xs={6} lg={4}>
            <div className="card-content">
                <div style={{ background: `url(${BackgroundImg})` }} className="sectionBg"></div>
                <div className='bg-black'>
                    <div className="imgBox">
                        <img src={ProfileDefault} alt="profileImg" />
                    </div>
                    <Typography variant='h3' className='name'>Jason Statham</Typography>
                    <Typography variant='h5' className='profession'>Actor</Typography>
                    <ul className='media d-flex justify-center align-center'>

                        <li>
                            <a>
                                <div className="circle">
                                    <Car />
                                </div>
                                <Typography>Lokatsiya</Typography>
                            </a>
                        </li>

                        <li>
                            <a>
                                <div className="circle">
                                    <Sms />
                                </div>
                                <Typography>Instagram</Typography>
                            </a>
                        </li>
                        <li>
                            <a>
                                <div className="circle">
                                    <Linkedin />
                                </div>
                                <Typography>Linkedin</Typography>
                            </a>
                        </li>
                        <li>
                            <a>

                                <div className="circle">
                                    <Sms />
                                </div>
                                <Typography>Sms</Typography>
                            </a>
                        </li>
                        <li>
                            <a>
                                <div className="circle">
                                    <Facebookcard />
                                </div>
                                <Typography>Facebook</Typography>
                            </a>
                        </li>
                        <li>
                            <a>
                                <div className="circle">
                                    <Telegramcard />
                                </div>
                                <Typography>Telegram</Typography>
                            </a>
                        </li>
                        <li>
                            <a>
                                <div className="circle">
                                    <PhoneCard />
                                </div>
                                <Typography>Telefon</Typography>
                            </a>
                        </li>
                    </ul>
                    <Skeleton className="skeletons" />
                    <Skeleton className="skeletons" />
                    <Skeleton className="skeletons" />
                    <Skeleton className="skeletons" />
                    <Skeleton className="skeletons" />
                    <Skeleton className="skeletons" />
                </div>
            </div>
            <h2>Version 1 (default)</h2>
        </Grid>)
}
