import React from 'react'

export default function LockGrey() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 10.25C17.8661 10.25 17.75 10.1339 17.75 10V8C17.75 6.40247 17.5304 4.93455 16.6437 3.8732C15.74 2.79134 14.2488 2.25 12 2.25C9.75122 2.25 8.26003 2.79134 7.35627 3.8732C6.46964 4.93455 6.25 6.40247 6.25 8V10C6.25 10.1339 6.13386 10.25 6 10.25C5.86614 10.25 5.75 10.1339 5.75 10V8C5.75 6.57122 5.92728 5.01738 6.76532 3.82501C7.57785 2.66893 9.08865 1.75 12 1.75C14.9113 1.75 16.4221 2.66893 17.2347 3.82501C18.0727 5.01738 18.25 6.57122 18.25 8V10C18.25 10.1339 18.1339 10.25 18 10.25Z" fill="white" stroke="#AAAAAA" />
            <path d="M12 18.75C10.4861 18.75 9.25 17.5139 9.25 16C9.25 14.4861 10.4861 13.25 12 13.25C13.5139 13.25 14.75 14.4861 14.75 16C14.75 17.5139 13.5139 18.75 12 18.75ZM12 13.75C10.7639 13.75 9.75 14.7639 9.75 16C9.75 17.2361 10.7639 18.25 12 18.25C13.2361 18.25 14.25 17.2361 14.25 16C14.25 14.7639 13.2361 13.75 12 13.75Z" fill="white" stroke="#AAAAAA" />
            <path d="M17 22.25H7C4.81222 22.25 3.55896 21.9094 2.8248 21.1752C2.09065 20.441 1.75 19.1878 1.75 17V15C1.75 12.8122 2.09065 11.559 2.8248 10.8248C3.55896 10.0906 4.81222 9.75 7 9.75H17C19.1878 9.75 20.441 10.0906 21.1752 10.8248C21.9094 11.559 22.25 12.8122 22.25 15V17C22.25 19.1878 21.9094 20.441 21.1752 21.1752C20.441 21.9094 19.1878 22.25 17 22.25ZM7 10.25C6.09618 10.25 5.34091 10.2922 4.72235 10.4228C4.09822 10.5546 3.57727 10.7836 3.17859 11.1831C2.77996 11.5825 2.55213 12.1037 2.42129 12.7271C2.2916 13.345 2.25 14.0989 2.25 15V17C2.25 17.9011 2.2916 18.655 2.42129 19.2729C2.55213 19.8963 2.77996 20.4175 3.17859 20.8169C3.57727 21.2164 4.09822 21.4454 4.72235 21.5772C5.34091 21.7078 6.09618 21.75 7 21.75H17C17.9038 21.75 18.6591 21.7078 19.2776 21.5772C19.9018 21.4454 20.4227 21.2164 20.8214 20.8169C21.22 20.4175 21.4479 19.8963 21.5787 19.2729C21.7084 18.655 21.75 17.9011 21.75 17V15C21.75 14.0989 21.7084 13.345 21.5787 12.7271C21.4479 12.1037 21.22 11.5825 20.8214 11.1831C20.4227 10.7836 19.9018 10.5546 19.2776 10.4228C18.6591 10.2922 17.9038 10.25 17 10.25H7Z" fill="white" stroke="#AAAAAA" />
        </svg>
    )
}
