import React from 'react'

export default function EditIcon() {
    return (
        <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M45 67.75H27C18.9124 67.75 13.2599 66.0203 9.6198 62.3802C5.97968 58.7401 4.25 53.0876 4.25 45V27C4.25 18.9124 5.97968 13.2599 9.6198 9.6198C13.2599 5.97968 18.9124 4.25 27 4.25H33C33.9539 4.25 34.75 5.04614 34.75 6C34.75 6.95386 33.9539 7.75 33 7.75H27C20.0497 7.75 15.1893 8.98107 12.0852 12.0852C8.98107 15.1893 7.75 20.0497 7.75 27V45C7.75 51.9503 8.98107 56.8107 12.0852 59.9148C15.1893 63.0189 20.0497 64.25 27 64.25H45C51.9503 64.25 56.8107 63.0189 59.9148 59.9148C63.0189 56.8107 64.25 51.9503 64.25 45V39C64.25 38.0461 65.0461 37.25 66 37.25C66.9539 37.25 67.75 38.0461 67.75 39V45C67.75 53.0876 66.0203 58.7401 62.3802 62.3802C58.7401 66.0203 53.0876 67.75 45 67.75Z" fill="white" stroke="white" />
            <path d="M19.4847 45.4327L19.485 45.4309L20.775 36.4009L20.7753 36.3985C21 34.7693 22.0886 32.6187 23.2435 31.4637L46.8835 7.82373C49.8127 4.89457 52.7005 3.49268 55.53 3.49268C58.3595 3.49268 61.2473 4.89457 64.1764 7.82373C67.3807 11.028 68.7536 14.2195 68.4723 17.3526C68.217 19.8985 66.8564 22.4361 64.1784 25.0847L64.1764 25.0866L40.5364 48.7266C39.3815 49.8816 37.2309 50.9701 35.6017 51.1949L35.5993 51.1952L26.5693 52.4852L26.5483 52.4882L26.5276 52.493C26.2016 52.5682 25.8676 52.5702 25.5 52.5702C23.7925 52.5702 22.2415 51.9558 21.1114 50.8544C19.7689 49.5105 19.1747 47.5463 19.4847 45.4327ZM49.358 10.2951L49.3564 10.2966L25.7164 33.9366C25.3776 34.2755 25.0418 34.8012 24.7797 35.3215C24.5176 35.8418 24.2972 36.4203 24.2258 36.8841L24.2258 36.8841L24.225 36.8895L22.935 45.9195L22.9347 45.9219C22.802 46.8839 22.9874 47.7847 23.5864 48.3837C24.1854 48.9827 25.0862 49.1682 26.0483 49.0355L26.0507 49.0352L35.0807 47.7452L35.086 47.7444C35.5485 47.6732 36.1338 47.4536 36.6586 47.1911C37.178 46.9315 37.7084 46.5949 38.038 46.2493L61.6735 22.6137C63.6541 20.6332 64.7719 18.7891 64.9379 16.9963L64.9379 16.9961C65.1382 14.8259 63.9932 12.6168 61.6759 10.2689L61.6735 10.2666C59.2242 7.81729 57.0608 6.8402 54.9852 7.0323C52.9378 7.2218 51.128 8.5402 49.358 10.2951Z" fill="white" stroke="white" />
            <path d="M59.1081 28.9255L59.0968 28.9217L59.0854 28.9185C51.3636 26.7458 45.2249 20.6075 43.0517 12.8859C42.7963 11.9544 43.328 10.9956 44.2681 10.7104C45.2033 10.4564 46.1525 10.9938 46.4079 11.9224L46.4079 11.9224L46.4087 11.9254C48.2559 18.483 53.4569 23.6839 60.0144 25.5311L60.0174 25.5319C60.9422 25.7862 61.4872 26.7644 61.2279 27.7073L61.2266 27.7119C61.018 28.5 60.3264 28.9898 59.55 28.9898C59.384 28.9898 59.2287 28.9657 59.1081 28.9255Z" fill="white" stroke="white" />
        </svg>

    )
}
