import React from 'react'

export default function Web() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22.25C6.34614 22.25 1.75 17.6539 1.75 12C1.75 6.34614 6.34614 1.75 12 1.75C17.6539 1.75 22.25 6.34614 22.25 12C22.25 17.6539 17.6539 22.25 12 22.25ZM12 2.25C6.62386 2.25 2.25 6.62386 2.25 12C2.25 17.3761 6.62386 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62386 17.3761 2.25 12 2.25Z" fill="white" stroke="white" />
            <path d="M7.99218 20.7499L8.64276 20.734L8.45983 20.1094C6.91671 14.8412 6.91671 9.15878 8.45983 3.89055L8.64276 3.26602L7.99218 3.25015C7.85525 3.24681 7.74999 3.14037 7.74999 3C7.74999 2.86614 7.86613 2.75 7.99999 2.75H8.99999C9.07538 2.75 9.15545 2.78938 9.20396 2.85197C9.25364 2.92609 9.26165 3.00702 9.23774 3.07574L9.2377 3.07572L9.23556 3.08214C7.32144 8.83471 7.32152 15.1654 9.23548 20.9276L9.23543 20.9276L9.23774 20.9343C9.26103 21.0012 9.25403 21.0798 9.2077 21.1523C9.15741 21.2106 9.07531 21.25 8.99999 21.25H7.99999C7.86613 21.25 7.74999 21.1339 7.74999 21C7.74999 20.8596 7.85525 20.7532 7.99218 20.7499Z" fill="white" stroke="white" />
            <path d="M15 21.7499C14.92 21.7499 14.84 21.7399 14.76 21.7099C14.37 21.5799 14.15 21.1499 14.29 20.7599C16.17 15.1099 16.17 8.88994 14.29 3.22994C14.16 2.83994 14.37 2.40994 14.76 2.27994C15.16 2.14994 15.58 2.35994 15.71 2.74994C17.7 8.70994 17.7 15.2699 15.71 21.2199C15.61 21.5499 15.31 21.7499 15 21.7499Z" fill="white" />
            <path d="M12 17.2C9.21 17.2 6.43 16.81 3.75 16.02C3.74 16.42 3.41 16.75 3 16.75C2.59 16.75 2.25 16.41 2.25 16V15C2.25 14.76 2.37 14.53 2.56 14.39C2.76 14.25 3.01 14.21 3.24 14.29C8.89 16.17 15.12 16.17 20.77 14.29C21 14.21 21.25 14.25 21.45 14.39C21.65 14.53 21.76 14.76 21.76 15V16C21.76 16.41 21.42 16.75 21.01 16.75C20.6 16.75 20.27 16.43 20.26 16.02C17.57 16.81 14.79 17.2 12 17.2Z" fill="white" />
            <path d="M21 9.75011C20.92 9.75011 20.84 9.74011 20.76 9.71011C15.11 7.83011 8.88003 7.83011 3.23003 9.71011C2.83003 9.84011 2.41003 9.63011 2.28003 9.24011C2.16003 8.84011 2.37003 8.42011 2.76003 8.29011C8.72003 6.30011 15.28 6.30011 21.23 8.29011C21.62 8.42011 21.84 8.85011 21.7 9.24011C21.61 9.55011 21.31 9.75011 21 9.75011Z" fill="white" />
        </svg>
    )
}
