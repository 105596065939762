import React, { useEffect, useState } from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup, Image, ImageWithZoom, ButtonFirst } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import "../../../components/carousel/carousel.css"
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Dialog, DialogTitle, Typography } from '@mui/material';
import Case from "../../../assets/images/case.png"


export default function CarouselInTemplates() {


    const [playing, setPlaying] = useState<boolean>(true)
    return (
        <div className="carouselWrapper">
            <Typography className='heading' variant='h5'>
                "Мои кейсы"
            </Typography>

            <div className="carousel-main">

                <CarouselProvider
                    naturalSlideWidth={100}
                    naturalSlideHeight={100}
                    totalSlides={3}
                    infinite={true}
                    isPlaying={false}
                    interval={3000}
                    lockOnWindowScroll={true}
                >

                    <Slider>
                        <Slide className="slide" onMouseOver={() => setPlaying(false)} onMouseLeave={() => setPlaying(true)} index={0}>
                            <Image hasMasterSpinner={true} src={Case} />
                            <div className="popupBox">
                                <div>
                                    <Typography className="title">
                                        Lorem ipsum
                                    </Typography>

                                    <Typography className="description_portfolio">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus, tempora.
                                    </Typography>



                                    <a className='portfolioLink'>Check on the website</a>

                                </div>
                            </div>
                        </Slide>
                        <Slide className="slide" onMouseOver={() => setPlaying(false)} onMouseLeave={() => setPlaying(true)} index={1}>
                            <Image hasMasterSpinner={true} src={Case} />
                            <div className="popupBox">
                                <div>
                                    <Typography className="title">
                                        Lorem ipsum
                                    </Typography>

                                    <Typography className="description_portfolio">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus, tempora.
                                    </Typography>



                                    <a className='portfolioLink'>Check on the website</a>

                                </div>
                            </div>
                        </Slide>
                        <Slide className="slide" onMouseOver={() => setPlaying(false)} onMouseLeave={() => setPlaying(true)} index={2}>
                            <Image hasMasterSpinner={true} src={Case} />
                            <div className="popupBox">
                                <div>
                                    <Typography className="title">
                                        Lorem ipsum
                                    </Typography>

                                    <Typography className="description_portfolio">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus, tempora.
                                    </Typography>



                                    <a className='portfolioLink'>Check on the website</a>

                                </div>
                            </div>
                        </Slide>
                    </Slider>


                    <ButtonBack><ArrowBackIosNewIcon /></ButtonBack>
                    <ButtonNext><ArrowForwardIosIcon /></ButtonNext>
                    <DotGroup />
                </CarouselProvider>

            </div>
        </div >


    )
}

// function PortfolioContent(props: any) {
//     const { open, onClose  } = props;

//     const handleClose = () => {
//         onClose();
//     };

//     return (
//         <Dialog onClose={handleClose} open={open}>
//             <DialogTitle>Set backup account</DialogTitle>

//         </Dialog>
//     );
// }
