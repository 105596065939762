import React, { useEffect } from 'react';
import { useGetCardQuery } from '../../services/cardsApi';
import { useParams } from 'react-router';

import BizzCardDefault from './templates/default';
import { trackPageVisit } from '../../utils/visitTracker';

import "./templatesStyles/general.css";
import "./templatesStyles/template-v1.css";
import "./templatesStyles/template-v2.css";
import "./templatesStyles/template-v3.css";
import { trackUserLocationForShare } from '../../utils/locationTracker';

export default function BizzCard() {
    const { id: id } = useParams();
    const { data: card } = useGetCardQuery(id);


    useEffect(() => {
        trackPageVisit(`${id}`);
        trackUserLocationForShare(`${id}`);
        console.log("hello");
    }, []);

    if (card) {
        if (card.template) {
            return <BizzCardDefault template={card.template} />
        }
    }
    return <BizzCardDefault template="v1" />

}
