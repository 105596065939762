import React from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, LabelList } from 'recharts';
import CellPhone from "../../../assets/images/cell-phone.png";
import Tablet from "../../../assets/images/tablet.png";
import Laptop from "../../../assets/images/laptop.png";
type DeviceType = {
    Mobile: string,
    Tablet: string,
    Desktop: string,
}

type PropsType = {
    devices: DeviceType,
}

export default function PieChartComponent(props: PropsType) {
    const {devices} = props;

    const completeDevices = {
        Mobile: devices.Mobile || 0,
        Tablet: devices.Tablet || 0,
        Desktop: devices.Desktop || 0,
    };


    const data = Object.entries(completeDevices).map(([key, value]) => ({
        name: key,
        value: value
    }));
    return (
        <div className='pieCompnonent d-flex align-center justofy-center'>

            <PieChart title='Traffic Source' width={250} height={250}>
                <Pie
                    data={data}
                    cx={120}
                    cy={120}
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={3}
                    dataKey="value"
                    label
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
            </PieChart>
            <div className='labels d-flex align-center justify-center'>
                <div>
                    <img src={CellPhone} alt="icon" />
                    <br />
                    <h4 style={{ color: "#0088FE" }}>Mobile</h4>
                </div>
                <div>
                    <img src={Tablet} alt="icon" />
                    <br />
                    <h4 style={{ color: "#00C49F" }}>Tablet</h4>
                </div>
                <div>
                    <img src={Laptop} alt="icon" />
                    <br />
                    <h4 style={{ color: "#FF8042" }}>Laptop</h4>
                </div>
            </div>
        </div>
    )
}


const COLORS = ['#0088FE', '#00C49F', '#FF8042'];