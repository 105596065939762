import React, { useContext, useEffect, useState } from 'react'
import { Button, Grid, Typography } from '@mui/material'
import Car from '../../../assets/images/car'
import AccordionProfile from '../../../components/accordionProfile/accordionProfile'
import NavbarMain from '../../../components/navbarMain/navbarMain'
import Carousel from '../../../components/carousel/carousel'
import ProfileForm from '../../../components/profileForm/profileForm'
import Tabs from '@mui/base/Tabs';
import { StyledTabsList, StyledTab, StyledTabPanel } from '../utils'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TelegramIcon from '@mui/icons-material/Telegram';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Footer from '../../../components/footer/footer'
import EditCard from '../../editCard/editCard'
import Linkedin from '../../../assets/images/linkedin'
import Sms from '../../../assets/images/sms'
import Telegramcard from '../../../assets/images/telegramcard'
import Facebookcard from '../../../assets/images/facebookcard'
import PhoneCard from '../../../assets/images/phoneCard'
import { EmailShareButton, FacebookShareButton, TelegramShareButton } from 'react-share'
import { Helmet } from "react-helmet";
import BackgroundImg from "../../../assets/images/profileBg.png"
import ProfileDefault from '../../../assets/images/profileDefault.png'
import { TypeAnimation } from 'react-type-animation'
import { Reveal } from '../../../reveal'
import CarouselInTemplates from '../components/carousel'
import { useNavigate } from 'react-router'
const card = {
    owner: "Hello world",
    type: "PREMIUM",
    corporate: false,
    name: "Pavel Durov",
    expertise: "Programmer/CEO",
    description: "",
    location: "United Arab Emirates",
    linkedin: "",
    email: "",
    facebook: "",
    telegram: "",
    instagram: "",
    mobile: 12919389182939,
    websiteLink: "https://hello.com",
    draftContent: "",

    timeFrom: "8:00",
    timeUntil: "20:00",
    services: "Software engineering, product management, philantrophy",
    qualities: "telegram, vkontakte",
    template: "v2",
}

export default function TemplateV2() {
    const navigate = useNavigate();

    const saveTemplateInfo = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        localStorage.setItem("templateType", "v2")
        navigate("/create_card/")
    }
    return (
        <>

            <Helmet>
                <meta name="title" content="Default Title" data-react-helmet="true" />
                <meta name="og:description"
                    content={`Привет! Я Pavel Durov - Programmer/CEO. Проверьте мою цифровую визитную карточку.`} data-react-helmet="true" />
                <meta name="og:keywords" content={'Jason, Pavel Durov, Programmer/CEO'} data-react-helmet="true" />
            </Helmet>

            <NavbarMain />

            <Grid container className={`myProfile bizzcard v2`}>
                <Grid className="wrapper" item xs={12}>
                    <Tabs
                        //@ts-ignore
                        value={0}
                        defaultValue={0}>

                        <StyledTabPanel value={0}>
                            <div className='wrapper-child'>
                                <div style={{
                                    background: `url(${BackgroundImg})`
                                }} className="sectionBg"></div>
                                <div className='bg-black'>
                                    <div className="imgBox">
                                        <img src={ProfileDefault} alt="profileImg" />
                                    </div>
                                    <Button onClick={saveTemplateInfo} className='saveProfile'>
                                        Use this template
                                    </Button>
                                    <Reveal width='100%'>
                                        <Typography variant='h3' className='name'>Pavel Durov</Typography>
                                    </Reveal>
                                    <Reveal width='100%'>
                                        <Typography variant='h5' className='profession'>Programmer/CEO</Typography>
                                    </Reveal>

                                    {/* MEDIA PART */}
                                    <ul className='media d-flex justify-center align-center'>

                                        <li>
                                            <a target="_blank">
                                                <Reveal>
                                                    <div className="circle">
                                                        <Car />
                                                    </div>
                                                </Reveal>
                                                <Reveal>
                                                    <Typography>Lokatsiya</Typography>
                                                </Reveal>
                                            </a>
                                        </li>

                                        < li >
                                            <a target="_blank" >
                                                <Reveal>
                                                    <div className="circle">
                                                        <Sms />
                                                    </div>
                                                </Reveal>
                                                <Reveal>
                                                    <Typography>Instagram</Typography>
                                                </Reveal>
                                            </a>
                                        </li>

                                        <li>
                                            <a target="_blank">
                                                <Reveal>
                                                    <div className="circle">
                                                        <Linkedin />
                                                    </div>
                                                </Reveal>
                                                <Reveal>
                                                    <Typography>Linkedin</Typography>
                                                </Reveal>
                                            </a>
                                        </li>

                                        <li>
                                            <a>

                                                <Reveal>
                                                    <div className="circle">
                                                        <Sms />
                                                    </div>
                                                </Reveal>
                                                <Reveal>
                                                    <Typography>Sms</Typography>
                                                </Reveal>
                                            </a>
                                        </li>

                                        <li>
                                            <a target="_blank">
                                                <Reveal>

                                                    <div className="circle">
                                                        <Facebookcard />
                                                    </div>
                                                </Reveal>
                                                <Reveal>
                                                    <Typography>Facebook</Typography>
                                                </Reveal>
                                            </a>
                                        </li>

                                        <li>
                                            <a target="_blank">
                                                <Reveal>

                                                    <div className="circle">
                                                        <Telegramcard />
                                                    </div>
                                                </Reveal>
                                                <Reveal>
                                                    <Typography>Telegram</Typography>
                                                </Reveal>
                                            </a>
                                        </li>
                                        <li>
                                            <a>
                                                <Reveal>
                                                    <div className="circle">
                                                        <PhoneCard />
                                                    </div>
                                                </Reveal>
                                                <Reveal>
                                                    <Typography>Telefon</Typography>
                                                </Reveal>
                                            </a>
                                        </li>

                                    </ul>
                                    <Button onClick={saveTemplateInfo} className='saveProfile'>
                                        Use this template
                                    </Button>
                                    <Reveal width='100%'>
                                        <Typography style={{ marginTop: "20px" }} className='aboutMe' variant="h5">
                                            Коротко обо мне
                                        </Typography>
                                    </Reveal>
                                    <Reveal width='100%'>
                                        <Typography className='description'>
                                            I've enjoyed every step of my journey in the film industry. As an Programmer/CEO,
                                            I strive to bring authenticity and depth to the characters I portray, particularly in the action genre. Throughout my career, I've been fortunate to collaborate with talented filmmakers and deliver performances that resonate with audiences worldwide. Being able to perform my own stunts is something I take great pride in—it adds an extra layer of realism to the adrenaline-fueled scenes viewers love. Off-screen, I cherish my privacy and value time spent with my loved ones.
                                        </Typography>
                                    </Reveal>

                                    {/* CONTACTS AND SERVICES */}
                                    <AccordionProfile card={card} />
                                    <div className="typingText">
                                        <TypeAnimation
                                            sequence={"Hardworking,Easy,Handsome".split(",").map((item: any) => item.trim())
                                                .filter((item: any) => item.replace(/\s/g, '').length > 0)
                                                .flatMap((item: any) => [item, 900])
                                            }
                                            style={{ fontSize: '6em', }}
                                            repeat={Infinity}
                                            className="typingAnimation"
                                        />
                                    </div>
                                </div>

                                {/* MY CASES */}
                                <CarouselInTemplates />
                                {/* FORM */}
                                <ProfileForm toName={card.name} toEmail={card.email} />


                                <div className="share">
                                    <Typography variant='h6'>Поделись моей визиткой с друзьями</Typography>
                                    <div className="d-flex align-center justify-center">
                                        <div className="item">
                                            <div className="iconBox orange">
                                                <EmailOutlinedIcon />
                                            </div>
                                            <Typography>SMS</Typography>
                                        </div>
                                        <FacebookShareButton
                                            disabled={true}
                                            url={`${process.env.REACT_APP_HOST + location.pathname}`}
                                            quote={'Jason, Programmer/CEO: Hello! Now you can check out all the info about my services and contacts in my e-business card!'}
                                            children={
                                                <div className="item">
                                                    <div className="iconBox blue">
                                                        <FacebookOutlinedIcon />
                                                    </div>
                                                    <Typography>Facebook</Typography>
                                                </div>}
                                        />
                                        <TelegramShareButton
                                            disabled={true}
                                            url={`${process.env.REACT_APP_HOST + location.pathname}`}
                                            title={`Jason, Programmer/CEO: Hello! Now you can check out all the info about my services and contacts in my e-business card!`}
                                            children={
                                                <div className="item">
                                                    <div className="iconBox lightBlue">
                                                        <TelegramIcon />
                                                    </div>
                                                    <Typography>Telegram</Typography>
                                                </div>}
                                        />
                                        <EmailShareButton
                                            disabled={true}
                                            url={`${process.env.REACT_APP_HOST + location.pathname}`}
                                            subject="Let me introduce my E-business card"
                                            body={`Jason, Programmer/CEO: Hello! Now you can check out all the info about my services and contacts in my e-business card!`}
                                            children={
                                                <div className="item">
                                                    <div className="iconBox red">
                                                        <AlternateEmailIcon />
                                                    </div>
                                                    <Typography>E-mail</Typography>
                                                </div>}

                                        />

                                    </div>
                                </div>
                               <div className="d-flex justify-center">
                                    <Button onClick={saveTemplateInfo} className='saveProfile'>
                                        Use this template
                                    </Button>
                                </div>
                            </div>
                            <div>
                            </div>
                        </StyledTabPanel>

                    </Tabs>

                </Grid>
            </Grid>
        </>
    )
}
