import React from 'react'

export default function Kollaj() {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30 45H18C12.6274 45 8.91242 43.8503 6.53105 41.4689C4.14968 39.0876 3 35.3726 3 30V18C3 12.6274 4.14968 8.91242 6.53105 6.53105C8.91242 4.14968 12.6274 3 18 3H30C35.3726 3 39.0876 4.14968 41.4689 6.53105C43.8503 8.91242 45 12.6274 45 18V30C45 35.3726 43.8503 39.0876 41.4689 41.4689C39.0876 43.8503 35.3726 45 30 45ZM18 5C13.3547 5 10.0568 5.82107 7.93895 7.93895C5.82107 10.0568 5 13.3547 5 18V30C5 34.6453 5.82107 37.9432 7.93895 40.0611C10.0568 42.1789 13.3547 43 18 43H30C34.6453 43 37.9432 42.1789 40.0611 40.0611C42.1789 37.9432 43 34.6453 43 30V18C43 13.3547 42.1789 10.0568 40.0611 7.93895C37.9432 5.82107 34.6453 5 30 5H18Z" fill="white" stroke="white" />
            <path d="M24 45C23.4561 45 23 44.5439 23 44V4C23 3.45614 23.4561 3 24 3C24.5439 3 25 3.45614 25 4V44C25 44.5439 24.5439 45 24 45Z" fill="white" stroke="white" />
            <path d="M24 20H4C3.45614 20 3 19.5439 3 19C3 18.4561 3.45614 18 4 18H24C24.5439 18 25 18.4561 25 19C25 19.5439 24.5439 20 24 20Z" fill="white" stroke="white" />
            <path d="M44 30H24C23.4561 30 23 29.5439 23 29C23 28.4561 23.4561 28 24 28H44C44.5439 28 45 28.4561 45 29C45 29.5439 44.5439 30 44 30Z" fill="white" stroke="white" />
        </svg>)
}
