import React from 'react'

export default function Email() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 20.75H7C5.26991 20.75 3.97362 20.254 3.1098 19.3902C2.24598 18.5264 1.75 17.2301 1.75 15.5V8.5C1.75 6.76991 2.24598 5.47362 3.1098 4.6098C3.97362 3.74598 5.26991 3.25 7 3.25H17C18.7301 3.25 20.0264 3.74598 20.8902 4.6098C21.754 5.47362 22.25 6.76991 22.25 8.5V15.5C22.25 17.2301 21.754 18.5264 20.8902 19.3902C20.0264 20.254 18.7301 20.75 17 20.75ZM7 3.75C5.49651 3.75 4.28256 4.11533 3.44895 4.94895C2.61533 5.78256 2.25 6.99651 2.25 8.5V15.5C2.25 17.0035 2.61533 18.2174 3.44895 19.0511C4.28256 19.8847 5.49651 20.25 7 20.25H17C18.5035 20.25 19.7174 19.8847 20.5511 19.0511C21.3847 18.2174 21.75 17.0035 21.75 15.5V8.5C21.75 6.99651 21.3847 5.78256 20.5511 4.94895C19.7174 4.11533 18.5035 3.75 17 3.75H7Z" fill="white" stroke="white" />
            <path d="M9.97575 11.6925L9.97577 11.6924L9.97182 11.6893L6.84385 9.19092C6.72844 9.0961 6.72097 8.93988 6.79784 8.84526C6.89256 8.72869 7.04954 8.72089 7.14449 8.79803L7.14448 8.79804L7.14774 8.80065L10.2768 11.2999C10.2769 11.3 10.2771 11.3001 10.2772 11.3002C10.7618 11.689 11.3909 11.8675 11.9948 11.8675C12.5987 11.8675 13.2277 11.689 13.7123 11.3003C13.7124 11.3002 13.7126 11.3 13.7127 11.2999L16.8418 8.80065L16.8418 8.80067L16.8451 8.79803C16.9526 8.71064 17.1074 8.73449 17.1812 8.8319L17.1864 8.83867L17.1917 8.84527C17.2791 8.95283 17.2553 9.10763 17.1579 9.18143L17.1578 9.18129L17.1477 9.18929L14.0177 11.6893L14.0177 11.6892L14.0109 11.6949C13.4747 12.1389 12.7431 12.37 11.9998 12.37C11.2573 12.37 10.5238 12.1393 9.97575 11.6925Z" fill="white" stroke="white" />
        </svg>
    )
}
