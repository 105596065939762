import axios from "axios";

export function trackCardShare(cardId: string): void {
    incrementShareCount(cardId);
}

const incrementShareCount = async (cardId: string) => {
    try {
        console.log(cardId)
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}cards/sharesIncrement/${cardId}`);
        console.log(response);
    } catch (error: any) {
        console.log(error.message);
        throw new Error(`Failed to track share: ${error.message}`);
    }
}
