import axios from "axios";
type LastVisitTime = string | null;
export function trackPageVisit(cardId: string): void {
    const currentTime = new Date().getTime();
    const lastVisitKey = `${cardId}_lastVisitTime`;
    const lastVisitTime = localStorage.getItem(lastVisitKey);

    let hoursDifference: number | null = null;
    if (lastVisitTime) {
        hoursDifference = (currentTime - parseInt(lastVisitTime)) / (1000 * 60 * 60);
    }

    if (hoursDifference === null || hoursDifference >= 1) {
        countVisit(cardId, lastVisitTime);
        localStorage.setItem(lastVisitKey, currentTime.toString());
    }
}


const countVisit = async (cardId: string, lastVisitTime: LastVisitTime) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}cards/visitsIncrement/${cardId}`, null, {
            headers: {
                'x-last-visit-time': lastVisitTime
            }
        });
        console.log(response.data.cityVisits);
    } catch (error: any) {
        console.log(error.message);
        throw new Error(`Failed to track visit: ${error.message}`);
    }
}
