import React from 'react'

export default function Facebookcard() {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.2083 26.5417H11.9583V18.0833H10.85C10.1733 18.0833 9.625 17.535 9.625 16.8583V14.6417C9.625 13.965 10.1733 13.4167 10.85 13.4167H11.9583V10.5C11.9583 8.085 13.9183 6.125 16.3333 6.125H19.4833C20.16 6.125 20.7083 6.67332 20.7083 7.34999V10.15C20.7083 10.8267 20.16 11.375 19.4833 11.375H17.2083V13.4167H19.4017C19.775 13.4167 20.1133 13.58 20.3467 13.86C20.58 14.14 20.6733 14.5133 20.6033 14.875L20.16 17.0917C20.0433 17.6633 19.5417 18.0717 18.9583 18.0717H17.2083V26.5417ZM13.7083 24.7917H15.4583V16.3333H18.5384L18.7717 15.1667H15.47V10.85C15.47 10.1733 16.0183 9.625 16.695 9.625H18.97V7.875H16.3333C14.8867 7.875 13.7083 9.05333 13.7083 10.5V15.1667H11.375V16.3333H13.7083V24.7917Z" fill="url(#paint0_linear_576_313)" />
            <path d="M17.5002 26.5418H10.5002C4.16516 26.5418 1.4585 23.8352 1.4585 17.5002V10.5002C1.4585 4.16516 4.16516 1.4585 10.5002 1.4585H17.5002C23.8352 1.4585 26.5418 4.16516 26.5418 10.5002V17.5002C26.5418 23.8352 23.8352 26.5418 17.5002 26.5418ZM10.5002 3.2085C5.12183 3.2085 3.2085 5.12183 3.2085 10.5002V17.5002C3.2085 22.8785 5.12183 24.7918 10.5002 24.7918H17.5002C22.8785 24.7918 24.7918 22.8785 24.7918 17.5002V10.5002C24.7918 5.12183 22.8785 3.2085 17.5002 3.2085H10.5002Z" fill="url(#paint1_linear_576_313)" />
            <defs>
                <linearGradient id="paint0_linear_576_313" x1="9.625" y1="6.125" x2="22.2664" y2="7.14016" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E8C488" />
                    <stop offset="1" stopColor="#8D6736" />
                </linearGradient>
                <linearGradient id="paint1_linear_576_313" x1="1.4585" y1="1.4585" x2="29.6358" y2="5.62673" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E8C488" />
                    <stop offset="1" stopColor="#8D6736" />
                </linearGradient>
            </defs>
        </svg>

    )
}
