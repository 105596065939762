import React from 'react'

export default function Telegramcard() {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.1995 19.4771L12.1996 19.4775C12.9552 21.7498 13.7933 23.0673 14.566 23.8121C15.3262 24.5451 16.0338 24.7352 16.5902 24.7352C17.1464 24.7352 17.854 24.5452 18.6159 23.8107C19.3901 23.0642 20.2312 21.7437 20.9925 19.4654L12.1995 19.4771ZM12.1995 19.4771L11.3595 16.9571L11.2805 16.7199L11.0433 16.6409L8.5233 15.8009C6.25691 15.0454 4.94228 14.2073 4.1988 13.4348C3.4671 12.6744 3.27686 11.9667 3.27686 11.4102C3.27686 10.86 3.46674 10.1519 4.19939 9.38796C4.94324 8.61234 6.25809 7.76875 8.52375 7.00773M12.1995 19.4771L8.52375 7.00773M8.52375 7.00773C8.52396 7.00766 8.52417 7.00759 8.52438 7.00752L18.4283 3.70621L18.429 3.70599C20.8141 2.90721 22.6474 3.19273 23.7283 4.26372C24.8063 5.33183 25.0937 7.17315 24.2944 9.55975L24.2942 9.56042L20.9926 19.4651L8.52375 7.00773ZM8.75491 7.7277L8.7549 7.7277C7.09929 8.28155 5.93979 8.89759 5.18367 9.50916C4.44344 10.1079 4.01519 10.7667 4.01519 11.4102C4.01519 12.0538 4.4435 12.7127 5.18416 13.3102C5.94057 13.9204 7.1003 14.5334 8.75597 15.0814C8.75612 15.0815 8.75628 15.0815 8.75643 15.0816L11.6954 16.0612L11.6954 16.0613L11.7019 16.0633C11.804 16.0958 11.8929 16.1847 11.9254 16.2868L11.9253 16.2868L11.9275 16.2933L12.9072 19.2323C12.9072 19.2324 12.9073 19.2326 12.9073 19.2327C13.4555 20.889 14.0717 22.049 14.6852 22.8056C15.2858 23.5462 15.9475 23.9735 16.5902 23.9735C17.2337 23.9735 17.8925 23.5453 18.4912 22.8051C19.1028 22.0489 19.7188 20.8894 20.2727 19.2338L20.2729 19.2333L23.5745 9.32832L23.5748 9.32739C23.8866 8.38605 24.0246 7.50294 23.9724 6.73444C23.9202 5.96634 23.6744 5.28061 23.1854 4.79165C22.6954 4.30164 22.0084 4.0593 21.242 4.01039C20.4752 3.96144 19.5954 4.10255 18.6604 4.4142L18.6599 4.41436L8.75491 7.7277Z" fill="url(#paint0_linear_576_1252)" stroke="url(#paint1_linear_576_1252)" />
            <path d="M11.5302 15.6604L11.5306 15.6599L15.7068 11.472C15.7069 11.472 15.707 11.4719 15.7071 11.4718C15.8502 11.329 16.0934 11.3291 16.2364 11.472C16.3795 11.6151 16.3795 11.8585 16.2364 12.0016L16.2359 12.0021L12.0592 16.1904L12.0534 16.1963L12.0477 16.2023C11.9942 16.2597 11.902 16.3002 11.7949 16.3002C11.6987 16.3002 11.6064 16.2662 11.5302 16.1899C11.3871 16.0469 11.3871 15.8035 11.5302 15.6604Z" fill="url(#paint2_linear_576_1252)" stroke="url(#paint3_linear_576_1252)" />
            <defs>
                <linearGradient id="paint0_linear_576_1252" x1="2.77686" y1="2.74902" x2="28.0233" y2="6.48166" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E8C488" />
                    <stop offset="1" stopColor="#8D6736" />
                </linearGradient>
                <linearGradient id="paint1_linear_576_1252" x1="2.77686" y1="2.74902" x2="28.0233" y2="6.48166" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E8C488" />
                    <stop offset="1" stopColor="#8D6736" />
                </linearGradient>
                <linearGradient id="paint2_linear_576_1252" x1="10.9229" y1="10.8647" x2="17.5747" y2="11.8463" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E8C488" />
                    <stop offset="1" stopColor="#8D6736" />
                </linearGradient>
                <linearGradient id="paint3_linear_576_1252" x1="10.9229" y1="10.8647" x2="17.5747" y2="11.8463" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E8C488" />
                    <stop offset="1" stopColor="#8D6736" />
                </linearGradient>
            </defs>
        </svg>

    )
}
