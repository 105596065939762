import React from 'react'

export default function Language() {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 45C12.4161 45 3 35.5839 3 24C3 12.4161 12.4161 3 24 3C35.5839 3 45 12.4161 45 24C45 35.5839 35.5839 45 24 45ZM24 5C13.5239 5 5 13.5239 5 24C5 34.4761 13.5239 43 24 43C34.4761 43 43 34.4761 43 24C43 13.5239 34.4761 5 24 5Z" fill="white" stroke="white" />
            <path d="M15.9722 40.9999L16.6228 40.984L16.4398 40.3595C13.3267 29.7312 13.3267 18.2688 16.4398 7.64055L16.6228 7.01602L15.9722 7.00015C15.4253 6.98681 15 6.55037 15 6C15 5.45614 15.4561 5 16 5H18C18.3152 5 18.6254 5.1593 18.8139 5.41178C19.0038 5.68633 19.0516 6.01723 18.9477 6.31574L18.9477 6.31572L18.9456 6.32214C15.1514 17.7247 15.1515 30.2754 18.9455 41.6976L18.9454 41.6976L18.9477 41.7043C19.0509 42.0008 19.0045 42.3293 18.8176 42.6028C18.6275 42.8408 18.315 43 18 43H16C15.4561 43 15 42.5439 15 42C15 41.4496 15.4253 41.0132 15.9722 40.9999Z" fill="white" stroke="white" />
            <path d="M29.6956 42.9517L29.6869 42.9485L29.6781 42.9455C29.1539 42.7708 28.8691 42.1945 29.0506 41.6888L29.0507 41.6889L29.0544 41.6777C32.8486 30.2752 32.8485 17.7245 29.0545 6.30226L29.0543 6.30176C28.8801 5.77911 29.1646 5.20647 29.6764 5.0348C30.2205 4.85897 30.7747 5.14507 30.9457 5.65798L30.9457 5.65822C34.8914 17.4755 34.8914 30.4843 30.9458 42.2813L30.9436 42.288L30.9415 42.2949C30.8083 42.7342 30.4101 42.9999 30 42.9999C29.8823 42.9999 29.785 42.9852 29.6956 42.9517Z" fill="white" stroke="white" />
            <path d="M7.64137 31.5605L7.01644 31.3763L7.00016 32.0276C6.98686 32.5593 6.5458 33.0001 6 33.0001C5.45614 33.0001 5 32.5439 5 32.0001V30.0001C5 29.6848 5.1593 29.3746 5.41178 29.1862C5.68633 28.9962 6.01722 28.9485 6.31574 29.0523L6.31572 29.0524L6.32214 29.0545C17.7246 32.8486 30.2954 32.8486 41.6979 29.0545L41.6979 29.0546L41.7043 29.0523C42.0046 28.9479 42.3377 28.9968 42.6133 29.1897C42.8697 29.3692 43.02 29.6707 43.02 30.0001V32.0001C43.02 32.5439 42.5639 33.0001 42.02 33.0001C41.4696 33.0001 41.0332 32.5748 41.0199 32.0279L41.004 31.3768L40.3791 31.5603C35.0459 33.1266 29.5334 33.9001 24 33.9001C18.4665 33.9001 12.9542 33.1266 7.64137 31.5605Z" fill="white" stroke="white" />
            <path d="M41.6956 18.9521L41.6868 18.9488L41.6779 18.9458C30.276 15.1519 17.7059 15.1517 6.30395 18.9452C5.76219 19.1204 5.21047 18.8378 5.03674 18.3292C4.87587 17.7795 5.16747 17.2248 5.67811 17.0546L5.67835 17.0545C17.4957 13.1088 30.5044 13.1088 42.3014 17.0544L42.3019 17.0546C42.8261 17.2293 43.1109 17.8056 42.9294 18.3113L42.9242 18.3259L42.9198 18.3408C42.8079 18.7262 42.4202 19.0002 42 19.0002C41.8823 19.0002 41.785 18.9856 41.6956 18.9521Z" fill="white" stroke="white" />
        </svg>

    )
}
